import React from "react"

type MainCard = {
  className?: string
}

const MainCard: React.FC<MainCard> = ({ className, children }) => (
  <div className={`bg-gray-100 p-8 rounded ${className}`}>{children}</div>
)

MainCard.defaultProps = {
  className: "",
}

export default MainCard
